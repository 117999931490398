import React, { useState, useContext,useEffect } from "react"
import { navigate } from "gatsby"
import { FirebaseContext} from '@components/Firebase'
import { Button,LoadingSpinner } from '@common'
import FlexWrapper from '@common/layout/FlexWrapper'
import Text from '@typo/Text'
import FlashMessage from '@notification/FlashMessage'
import styled from 'styled-components'
import gift from "@images/gift.svg"
import { useTranslation } from 'react-i18next'

const ImageWrapper = styled.div`
  height:200px;
  width:200px;
`

const GiftImage= styled.img`
  width:100%;
`

const DiscountTitle = styled.h2`
  font-size:3.5rem;
  margin:20px 0px 0px 0px;
`

const DiscountDescription = styled(Text)`
  padding: 0px 15%;
`

const CancelOfferWrapper = styled(FlexWrapper)`
  height:80vh;
`

const CancelOptions = (props) => {
  const {firebase} = useContext(FirebaseContext);
  const [flashMessage, setFlashMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [planInterval, setPlanInterval] = useState('');
  const { t } = useTranslation()
  

  useEffect(() => {
    if(props.location.state !== undefined){
      setPlanInterval(props.location.state.planInterval);
    }
  },[props.location.state]);

  async function sendDiscountRequest(){
    setLoading(true);
    if(firebase){
      await firebase.applyDiscount().then(()=> {
        setFlashMessage({message:t('cancel-offers.flashMessage-1'),type:"success"});
        setLoading(false);
        setTimeout(function(){
          navigate('/');
        }, 2000);
      }).catch((error) => {
        setFlashMessage({message:t('cancel-offers.flashMessage-2'),type:"error"});
        setLoading(false);    
      })
    }
  }

  function handleCancelClick(){
    setLoading(true);
    firebase.cancelSubscription().then(()=> {
      setLoading(false);
      setFlashMessage({message:t('cancel-offers.flashMessage-3'),type:"success"});
      setTimeout(function(){
        navigate('/');
      }, 2000);
    }).catch((error) => {
      setLoading(false);
      setFlashMessage({message:t('cancel-offers.flashMessage-4'),type:"error"});      
    })
  }

  return (
    <CancelOfferWrapper around column>
      <ImageWrapper><GiftImage src={gift}/></ImageWrapper>
      {planInterval === 'month' 
      ?
        <>
          <DiscountTitle>{t('cancel-offers.monthlyDiscountTitle')}</DiscountTitle>
          <DiscountDescription center>{t('cancel-offers.monthlyDiscountDescription')}</DiscountDescription>
        </>
      :
        <>
          <DiscountTitle>{t('cancel-offers.annualDiscountTitle')}</DiscountTitle>
          <DiscountDescription center>{t('cancel-offers.annualDiscountDescription')}</DiscountDescription>
        </>
      }
      <Button type="button"  block onClick={sendDiscountRequest}>{t('cancel-offers.acceptButton')}</Button>
      <Button inverted border block marginTop onClick={handleCancelClick}>{t('cancel-offers.cancelButton')}</Button>
      {flashMessage && <FlashMessage message={flashMessage}/>}
      {loading && <LoadingSpinner/>}
    </CancelOfferWrapper>
  )
}

export default CancelOptions